import { Box, GridItem, Img, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "../Assets/Logo.svg"

class Footer extends React.Component {
    render() {
        return (
            <Box padding={["1rem 0px", "1rem", "1rem 2rem", "1rem 4rem"]} backgroundColor="brand.900">
                <SimpleGrid columns={{ base: 1, md: 1, xl: 4 }} gap="3" marginY="4rem">
                    <GridItem marginBottom={{base: "2rem", lg: "0rem"}}>
                        <Img width={{ base: "40%", lg: "auto" }} src={Logo} alt="kamakhya" />
                    </GridItem>
                    <GridItem marginBottom={{base: "2rem", lg: "0rem"}}>
                        <Text fontWeight="bold" fontSize="1.25rem" textDecoration="underline" color="brand.300" marginBottom={{base: "1rem", lg: "2rem"}}>Contact</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom={{base: "0.5rem", lg: "1rem"}} color="brand.700">Connect with us on social media</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom={{base: "0.5rem", lg: "1rem"}} color="brand.700">Informations and directions to our venue</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.700">Got a question? Send us a message</Text>
                    </GridItem>
                    <GridItem marginBottom={{base: "2rem", lg: "0rem"}}>
                        <Text fontWeight="bold" fontSize="1.25rem" textDecoration="underline" color="brand.300" marginBottom={{base: "1rem", lg: "2rem"}}>Sub Pages</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom={{base: "0.5rem", lg: "1rem"}} color="brand.700">Connect with us on social media</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom={{base: "0.5rem", lg: "1rem"}} color="brand.700">Informations and directions to our venue</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.700">Got a question? Send us a message</Text>
                    </GridItem>
                    <GridItem>
                        <Text fontWeight="bold" fontSize="1.25rem" textDecoration="underline" color="brand.300" marginBottom={{base: "1rem", lg: "2rem"}}>Sub Pages</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom={{base: "0.5rem", lg: "1rem"}} color="brand.700">Connect with us on social media</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom={{base: "0.5rem", lg: "1rem"}} color="brand.700">Informations and directions to our venue</Text>
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.700">Got a question? Send us a message</Text>
                    </GridItem>
                </SimpleGrid>
            </Box>
        )
    }
}

export default Footer