import { Search2Icon } from "@chakra-ui/icons";
import { Flex, Input, InputRightAddon, InputGroup, Box, Text, Img } from "@chakra-ui/react";
import React from "react";
import Divider from "../Components/Divider"
import { client } from "../Utils/contentful";
import Moment from "react-moment";
import Nothing from "../Assets/Nothing to Show.jpg"
import PageHeadings from "../Components/PageHeadings"

class Blogs extends React.Component {
    constructor() {
        super()
        this.state = {
            searchArticles: "",
            allBlogs: []
        }
    }

    componentDidMount() {
        client.getEntries({ content_type: "blogs" }).then(response => {
            this.setState({ allBlogs: response.items })
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const blogClick = (id) => {
            window.location.href = `blogs/${id}`
        }

        const filteredArray = this.state.allBlogs.filter(eachBlog => eachBlog.fields.heading?.toLowerCase().includes(this.state.searchArticles.toLowerCase()))

        return (
            <Box>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Flex justifyContent="end" paddingTop="8rem" paddingBottom="6rem">
                        <Box>
                            <InputGroup border="1px" rounded="8" borderColor="brand.700">
                                <Input onChange={onChange} value={this.state.searchArticles} name="searchArticles" placeholder='Search Articles' border="0" />
                                <InputRightAddon bgColor="transparent" border="0">
                                    <Search2Icon />
                                </InputRightAddon>
                            </InputGroup>
                        </Box>
                    </Flex>
                    <Box marginBottom="1rem">
                        <PageHeadings heading="PUBLICATIONS" />
                    </Box>
                    <Text fontSize={{base: "0.75rem", lg: "1rem"}} color="brand.700" maxWidth="600px" marginBottom="6rem">
                        A primer on poll predictions in India, evaluating different
                        pollsters and assigning them a rank based on their track records in
                        Indian elections.
                    </Text>
                    <Text fontWeight="bold" fontSize={{base: "1.125rem", lg: "1.5rem"}} fontFamily="body" color="brand.300">
                        Latest Articles
                    </Text>
                    {filteredArray.length === 0 &&
                        <Flex alignItems="center" marginBottom="6rem" direction="column">
                            <Img width="200px" src={Nothing} alt="kamakhya" />
                            <Text color="brand.700">No blogs to show. Please try again later.</Text>
                        </Flex>}
                    <Box marginBottom="6rem">
                        {filteredArray.length !== 0 && filteredArray.map(eachBlog => {
                            return (
                                <Box key={eachBlog.fields.id}>
                                    <Flex flexDirection={{ base: "column", lg: "row" }} cursor="pointer" onClick={() => blogClick(eachBlog.fields.id)} gap="3" paddingBottom="1rem" marginTop="2rem">
                                        <Img width={{base: "100%", lg: "300px"}} height="200px" rounded="5" src={eachBlog.fields.thumbnail.fields.file.url} alt="kamakhya" />
                                        <Box>
                                            <Text fontSize="1.25rem" fontFamily="heading" fontWeight="bold">{eachBlog.fields.heading}</Text>
                                            <Text fontSize={{base: "0.75rem", lg: "1rem"}} textAlign="justify" color="brand.700" marginBottom="3rem">
                                                {eachBlog.fields.miniDescription}
                                            </Text>
                                            <Flex gap="4" fontSize={{base: "0.75rem", lg: "1rem"}}>
                                                <Text color="brand.800">
                                                    <Moment format="DD MMMM YYYY">
                                                        {eachBlog.fields.date}
                                                    </Moment>
                                                </Text>
                                                <Text color="brand.800">.</Text>
                                                <Text color="brand.800">
                                                    {eachBlog.fields.readingTime} {eachBlog.fields.readingTime === 1 ? "min" : "mins"}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                    <Box margin="auto" bgColor="brand.300" height="3px" width={{base: "33%", lg: "60%", xl: "80%"}}></Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <Divider />
            </Box>

        )
    }
}

export default Blogs