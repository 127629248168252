import { Box } from "@chakra-ui/react";
import React from "react";

const Divider = () => {
    return (
        <Box width="100%" height="0.5rem" bgGradient="linear(to-l, brand.200, brand.300)">
        </Box>
    )
}

export default Divider