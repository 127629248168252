import { Box, Heading, Text } from "@chakra-ui/react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

export const jobOptions = {
    renderNode: {
        [BLOCKS.HEADING_1]: (node, children) =>
            <div>
                <Heading as="h1" size="3xl" fontFamily="heading">
                    {children}
                </Heading>
            </div>,
        [BLOCKS.HEADING_3]: (node, children) =>
            <div>
                <Heading as="h3" size="xl" fontFamily="heading">
                    {children}
                </Heading>
            </div>,
        [MARKS.BOLD]: (node, children) =>
            <div>
                <Text as="b" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.ITALIC]: (node, children) =>
            <div>
                <Text as="i" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.UNDERLINE]: (node, children) =>
            <div>
                <Text as="u" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [BLOCKS.UL_LIST]: (node, children) =>
            <div>
                <ul>
                    {children}
                </ul>
            </div>,
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <Box marginBottom="1rem">
                <Text fontSize={{base: "0.75rem", lg: "1rem"}} fontFamily="body" textAlign="justify">
                    {children}
                </Text>
            </Box>
    }
}