import { Flex, Text, Box, Heading, Card, CardBody, GridItem, Button, Img, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import CareerLanding from "../Assets/CareerLanding.png"
import PageHeadings from "../Components/PageHeadings"
import { buttonStyleHover } from "../Styling/components";
import { client } from "../Utils/contentful";
import Divider from "../Components/Divider"
import TestimonialCarousel from "../Components/TestimonialCarousel"
import Testimonials from "../Data/Testimonials.json"
import TestimonialCard from "../Components/TestimonialCard";
import Nothing from "../Assets/Nothing to Show.jpg"

class Career extends React.Component {
    constructor() {
        super()
        this.state = {
            allOpenings: [],
            selectedOpenings: [],
            departments: [],
            selectedDepartment: "all"
        }
    }

    componentDidMount() {
        client.getEntries({ content_type: "jobPostings" }).then(response => {
            this.setState({ allOpenings: response.items, selectedOpenings: response.items })
            const allDepartments = response.items.map(eachItem => eachItem.fields.department)
            const departments = [...new Set(allDepartments)]
            this.setState({ departments })
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onDepartmentClick = (department) => {
            const { allOpenings } = this.state
            this.setState({ selectedDepartment: department })
            if (department === "all") {
                this.setState({ selectedOpenings: this.state.allOpenings })
            }
            else {
                const filteredOpenings = allOpenings.filter(eachOpening => eachOpening.fields.department.toLowerCase() === department.toLowerCase())
                this.setState({ selectedOpenings: filteredOpenings })
            }
        }

        const onCareerSubmit = (id) => {
            window.location.href = `/career/${id}`
        }

        return (
            <Box>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent="space-between" gap="3" alignItems="center" paddingTop="8rem" paddingBottom="6rem">
                        <Img src={CareerLanding} alt="kamakhya" />
                        <Text fontSize={{ base: "0.75rem", lg: "1rem" }} width={{ base: "100%", xl: "35%" }} textAlign={{ base: "justify", xl: "center" }}>
                            We're excited to welcome talented individuals who share our passion
                            for innovation and excellence. Joining our team means becoming part of a
                            dynamic environment where your skills and ideas will help shape the future
                            of our company. <br /><br />
                            Together, we can achieve extraordinary results and drive lasting success.
                        </Text>
                    </Flex>
                    {/* <Flex justifyContent="center" marginBottom="6rem">
                        <PageHeadings heading="OUR EMPLOYEE FEEDBACKS" />
                    </Flex>
                    <Box marginBottom="6rem">
                        <TestimonialCarousel>
                            {Testimonials.map(eachTestimonial => {
                                return (
                                    <TestimonialCard name={eachTestimonial.name} description={eachTestimonial.description} subTitle={eachTestimonial.subTitle} />
                                )
                            })}
                        </TestimonialCarousel>
                    </Box> */}
                </Box>
                <Box bgGradient="linear(to-r, brand.300, brand.100)" textAlign="center" p="0.5rem 0px" marginBottom={{base: "2rem", lg:"6rem"}}>
                    <Heading margin="0px" fontSize={{ base: "1.5rem", lg: "1.825rem", xl: "2.5rem" }} color="brand.600">WE'RE HIRING</Heading>
                </Box>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Text fontSize={{ base: "1.125rem", lg: "1.5rem" }} color="brand.100" fontWeight="bold">BE A PART OF OUR TEAM</Text>
                    <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.800" marginBottom="2rem">We're looking for passionate people to join our team.</Text>
                    {/* <Flex marginBottom="2rem" paddingBottom="1rem" gap="3" flexWrap="wrap" borderBottom="1px">
                        <Card onClick={() => onDepartmentClick("all")}
                            bgGradient={this.state.selectedDepartment === "all" && "linear(to-r, brand.300, brand.100)"}
                            color={this.state.selectedDepartment === "all" ? "brand.600" : "brand.100"}
                            variant="outline" cursor="pointer" padding="0.25rem 0.75rem" borderRadius="3rem">
                            View All
                        </Card>
                        {this.state.departments.map(eachDepartment => {
                            return (
                                <Card onClick={() => onDepartmentClick(eachDepartment)}
                                    bgGradient={this.state.selectedDepartment === eachDepartment && "linear(to-r, brand.300, brand.100)"}
                                    color={this.state.selectedDepartment === eachDepartment ? "brand.600" : "brand.100"}
                                    key={eachDepartment} variant="outline" cursor="pointer" padding="0.25rem 0.75rem" borderRadius="3rem">
                                    {eachDepartment}
                                </Card>
                            )
                        })}
                    </Flex> */}
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="3" marginBottom="2rem">
                        {this.state.selectedOpenings.map(eachOpening => {
                            return (
                                <GridItem key={eachOpening.fields.id}>
                                    <Card variant="outline" boxShadow="md">
                                        <CardBody>
                                            <Text color="brand.100" fontSize="1.25rem" fontWeight="bold">{eachOpening.fields.title}</Text>
                                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.800" marginBottom="1rem">
                                                {eachOpening.fields.miniDescription}
                                            </Text>
                                            <Flex fontSize={{ base: "0.75rem", lg: "1rem" }} gap="3" flexWrap="wrap" marginBottom="1rem">
                                                <Card variant="outline" padding="0.125rem 0.5rem" borderRadius="0.5rem">
                                                    {eachOpening.fields.employmentType}
                                                </Card>
                                                <Card variant="outline" padding="0.125rem 0.5rem" borderRadius="0.5rem">
                                                    Experience: {eachOpening.fields.experience} {eachOpening.fields.experience === 1 ? "Year" : "Years"}
                                                </Card>
                                                <Card variant="outline" padding="0.125rem 0.5rem" borderRadius="0.5rem">
                                                    {eachOpening.fields.workMode}
                                                </Card>
                                            </Flex>
                                            <Button fontSize={{ base: "0.75rem", lg: "1rem" }} fontWeight={{ base: "normal", lg: "bold" }} onClick={() => onCareerSubmit(eachOpening.fields.id)} _hover={buttonStyleHover} bgGradient="linear(to-t, brand.200, brand.300)" color="brand.600" padding="0px 1rem">
                                                Apply Now
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            )
                        })}
                    </SimpleGrid>
                    {this.state.selectedOpenings.length === 0 &&
                        <Flex alignItems="center" marginBottom="6rem" direction="column">
                            <Img width="200px" src={Nothing} alt="kamakhya" />
                            <Text color="brand.700">No job openings to show. Please try again later.</Text>
                        </Flex>}
                </Box>
                <Divider />
            </Box>
        )
    }
}

export default Career