import { Box, Card, CardBody, Flex, GridItem, Heading, Img, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AboutLanding from "../Assets/AboutUsLanding.svg"
import Projects from "../Assets/Projects.png"
import Strength from "../Assets/Strength.png"
import People from "../Assets/People.png"
import Data from "../Assets/Data.png"
import Survey from "../Assets/Survey.svg"
import Opinion from "../Assets/Opinion.svg"
import Campaigns from "../Assets/Campaigns.svg"
import Policy from "../Assets/Policy.jpg"
import PageHeadings from "../Components/PageHeadings"
import Team from "../Assets/Team.jpg"
import Divider from "../Components/Divider"
import TeamCarousel from "../Components/TeamCarousel"
import TeamMembers from "../Data/Team.json"
import TeamCard from "../Components/TeamCard"

class AboutUs extends React.Component {
    render() {
        return (
            <Box>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Flex justifyContent="center" paddingTop="8rem" paddingBottom={{ base: "2rem", lg: "6rem" }} >
                        <PageHeadings heading="ABOUT US" />
                    </Flex>
                    <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent="space-between" gap="3" marginBottom="6rem">
                        <Img src={AboutLanding} alt="kamakhya" width={{ base: "60%", lg: "auto" }} margin={{ base: "auto", lg: "0" }} />
                        <Card bg="brand.400" maxW={{ base: "100%", xl: "600px" }} p={{ base: "0", lg: "1rem" }}>
                            <CardBody>
                                <Text fontSize="1rem" color="brand.100" textAlign="justify">
                                    Kamakhya Analytics is a revolutionary survey agency,
                                    redefining the manner in which surveys are conducted in the country.
                                    We deploy cutting-edge technology to extract in-depth insights from
                                    large volumes of data collected from on-ground resources. We are at
                                    the forefront of synchronizing technology and innovative data
                                    collection techniques to determine the rhythm of public sentiments
                                    nationwide. We are a group of professionals from diverse backgrounds,
                                    enthusiastic about our Democracy and eager to inform public policy
                                    based on inputs from a wide range of people from diverse walks of
                                    life and transform the manner in which agencies interact with the
                                    people they wish to serve.
                                </Text>
                            </CardBody>
                        </Card>
                    </Flex>
                    <Box display={{ base: "none", lg: "block" }}>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap="3" marginBottom="6rem">
                            <GridItem>
                                <Flex justifyContent="center" gap="3">
                                    <Box>
                                        <Flex alignItems="center" gap="3">
                                            <Img width="75px" height="75px" src={Projects} alt="kamakhya" />
                                            <Flex direction="column" textAlign="center">
                                                <Heading fontSize="4rem" color="brand.100">28</Heading>
                                                <Text fontWeight="bold">Projects Worked On</Text>
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </GridItem>
                            <GridItem>
                                <Flex justifyContent="center" gap="3">
                                    <Box>
                                        <Flex alignItems="center" gap="3">
                                            <Img width="75px" height="75px" src={Data} alt="kamakhya" />
                                            <Flex direction="column" textAlign="center">
                                                <Heading fontSize="4rem" color="brand.100">7.88 Cr</Heading>
                                                <Text fontWeight="bold">Data Points Collected</Text>
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </GridItem>
                            <GridItem>
                                <Flex justifyContent="center" gap="3">
                                    <Box>
                                        <Flex alignItems="center" gap="3">
                                            <Img width="75px" height="75px" src={People} alt="kamakhya" />
                                            <Flex direction="column" textAlign="center">
                                                <Heading fontSize="4rem" color="brand.100">40L</Heading>
                                                <Text fontWeight="bold">People Surveyed</Text>
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </GridItem>
                            <GridItem>
                                <Flex justifyContent="center" gap="3">
                                    <Box>
                                        <Flex alignItems="center" gap="3">
                                            <Img width="75px" height="75px" src={Strength} alt="kamakhya" />
                                            <Flex direction="column" textAlign="center">
                                                <Heading fontSize="4rem" color="brand.100">800+</Heading>
                                                <Text fontWeight="bold">Current Strength</Text>
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </GridItem>
                        </SimpleGrid>
                    </Box>
                    <SimpleGrid display={{ base: "block", lg: "none" }} columns={{ base: 2 }} gap="3" marginBottom="6rem" >
                        <GridItem>
                            <Card mb="1rem" border="1px solid #A0A0A0" textAlign="center">
                                <CardBody>
                                    <Flex gap="3" justifyContent="center" alignItems="center" mb="1rem">
                                        <Img width="75px" height="75px" src={Projects} alt="kamakhya" />
                                        <Heading fontSize="2rem" color="brand.100">28</Heading>
                                    </Flex>
                                    <Text fontWeight="bold">Projects Worked On</Text>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card mb="1rem" border="1px solid #A0A0A0" textAlign="center">
                                <CardBody>
                                    <Flex gap="3" justifyContent="center" alignItems="center" mb="1rem">
                                        <Img width="75px" height="75px" src={Data} alt="kamakhya" />
                                        <Heading fontSize="2rem" color="brand.100">7.88 Cr</Heading>
                                    </Flex>
                                    <Text fontWeight="bold">Data Points Collected</Text>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card mb="1rem" border="1px solid #A0A0A0" textAlign="center">
                                <CardBody>
                                    <Flex gap="3" justifyContent="center" alignItems="center" mb="1rem">
                                        <Img width="75px" height="75px" src={People} alt="kamakhya" />
                                        <Heading fontSize="2rem" color="brand.100">40L</Heading>
                                    </Flex>
                                    <Text fontWeight="bold">People Surveyed</Text>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card mb="1rem" border="1px solid #A0A0A0" textAlign="center">
                                <CardBody>
                                    <Flex gap="3" justifyContent="center" alignItems="center" mb="1rem">
                                        <Img width="75px" height="75px" src={Strength} alt="kamakhya" />
                                        <Heading fontSize="2rem" color="brand.100">800+</Heading>
                                    </Flex>
                                    <Text fontWeight="bold">Current Strength</Text>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, lg: 2 }} gap="3" marginBottom="6rem" >
                        <GridItem>
                            <Card border="2px" borderColor="brand.300" variant="elevated" align="center" h="100%">
                                <CardBody textAlign="center">
                                    <Text color="brand.300" fontWeight="bold">NATIONWIDE SURVEYS</Text>
                                    <Text color="brand.800" fontSize="small" marginBottom="2rem">Public Perception About Government</Text>
                                    <Flex gap="3" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Img src={Survey} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        <Text fontSize="1rem" color="brand.100" textAlign="justify">
                                            Conduct nationwide surveys to determine public perception
                                            about Government Projects, Government schemes, Government
                                            programs and Government Policies which directly or indirectly
                                            benefit the people at large.
                                        </Text>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card border="2px" borderColor="brand.300" variant="elevated" align="center" h="100%">
                                <CardBody textAlign="center">
                                    <Text color="brand.300" fontWeight="bold">PUBLIC OPINION STUDIES</Text>
                                    <Text color="brand.800" fontSize="small" marginBottom="2rem">
                                        Assessing Political Dynamics and Policy Impact
                                    </Text>
                                    <Flex gap="3" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Img src={Opinion} alt="kamakhya" maxWidth={{ base: "100%", lg: 175 }} />
                                        <Text fontSize="1rem" color="brand.100" textAlign="justify">
                                            Study the opinions, behaviours and views of people about the
                                            functioning of political parties in their respective areas as well
                                            as the impact of government policies and their execution to assist
                                            in the execution of policies in related areas.
                                        </Text>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card border="2px" borderColor="brand.300" variant="elevated" align="center" h="100%">
                                <CardBody textAlign="center">
                                    <Text color="brand.300" fontWeight="bold">IMPACT OF PUBLIC CAMPAIGNS</Text>
                                    <Text color="brand.800" fontSize="small" marginBottom="2rem">
                                        Evaluating Public Awareness Campaigns to Enhance Impact in Key Areas
                                    </Text>
                                    <Flex gap="3" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Img src={Campaigns} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        <Text fontSize="1rem" color="brand.100" textAlign="justify">
                                            To determine the impact of the public awareness campaigns
                                            in areas of education, social justice, women empowerment
                                            and others led by government agencies in order to generate
                                            inputs to enhance the impact of the same.
                                        </Text>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card border="2px" borderColor="brand.300" variant="elevated" align="center" h="100%">
                                <CardBody textAlign="center">
                                    <Text color="brand.300" fontWeight="bold">GENERAL POLICY RESEARCH</Text>
                                    <Text color="brand.800" fontSize="small" marginBottom="2rem">
                                        Assessing Government Policies for Future Impact
                                    </Text>
                                    <Flex gap="3" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Img src={Policy} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        <Text fontSize="1rem" color="brand.100" textAlign="justify">
                                            Conduct general research with regards to government policies
                                            and schemes in the areas of education, health, agriculture,
                                            commerce and industry, sanitation and environment to determine
                                            the scope and impact of current policies and further provide
                                            inputs for future assistance related to campaigns and projects.
                                        </Text>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </SimpleGrid>
                    {/* <Flex justifyContent="center" marginBottom="2rem">
                        <PageHeadings heading="OUR TEAM" />
                    </Flex> */}
                    {/* <Box marginBottom="6rem">
                        <TeamCarousel>
                            {TeamMembers.map(eachMember => {
                                return (
                                    <TeamCard name={eachMember.name} twitter={eachMember.twitter} linkedin={eachMember.linkedin} gmail={eachMember.gmail} 
                                        designation={eachMember.designation} description={eachMember.description}
                                    />
                                )
                            })}
                        </TeamCarousel>
                    </Box>
                    <Box marginBottom="6rem">
                        <Img src={Team} alt="kamakhya" rounded="8" width={{ base: "100%", xl: "60%" }} margin="auto" />
                    </Box> */}
                </Box>
                <Divider />
            </Box>
        )
    }
}

export default AboutUs