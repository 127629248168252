export const mapData = {
    "AP": [
        {
            "name": "Total samples collected",
            "value": 38425
        },
        {
            "name": "Total constituencies covered",
            "value": 41
        },
        {
            "name": "Total duration of surveys",
            "value": "39 days"
        },
        {
            "name": "No. of villages covered",
            "value": 752
        },
        {
            "name": "Total polling agents",
            "value": 124
        }
    ],
    "CG": [
        {
            "name": "Total samples collected",
            "value": 316889
        },
        {
            "name": "Total constituencies covered",
            "value": 88
        },
        {
            "name": "Total duration of surveys",
            "value": "251 days"
        },
        {
            "name": "No. of villages covered",
            "value": 1293
        },
        {
            "name": "Total polling agents",
            "value": 498
        }
    ],
    "GA": [
        {
            "name": "Total samples collected",
            "value": 3470
        },
        {
            "name": "Total constituencies covered",
            "value": 8
        },
        {
            "name": "Total duration of surveys",
            "value": "11 days"
        },
        {
            "name": "No. of villages covered",
            "value": 25
        },
        {
            "name": "Total polling agents",
            "value": 27
        }
    ],
    "HP": [
        {
            "name": "Total samples collected",
            "value": 78301
        },
        {
            "name": "Total constituencies covered",
            "value": 62
        },
        {
            "name": "Total duration of surveys",
            "value": "122 days"
        },
        {
            "name": "No. of villages covered",
            "value": 6
        },
        {
            "name": "Total polling agents",
            "value": 129
        }
    ],
    "HR": [
        {
            "name": "Total samples collected",
            "value": 703580
        },
        {
            "name": "Total constituencies covered",
            "value": 90
        },
        {
            "name": "Total duration of surveys",
            "value": "208 days"
        },
        {
            "name": "No. of villages covered",
            "value": 4461
        },
        {
            "name": "Total polling agents",
            "value": 1265
        }
    ],
    "JH": [
        {
            "name": "Total samples collected",
            "value": 246935
        },
        {
            "name": "Total constituencies covered",
            "value": 81
        },
        {
            "name": "Total duration of surveys",
            "value": "165 days"
        },
        {
            "name": "No. of villages covered",
            "value": 1524
        },
        {
            "name": "Total polling agents",
            "value": 659
        }
    ],
    "JK": [
        {
            "name": "Total samples collected",
            "value": 38480
        },
        {
            "name": "Total constituencies covered",
            "value": 27
        },
        {
            "name": "Total duration of surveys",
            "value": "71 days"
        },
        {
            "name": "No. of villages covered",
            "value": 401
        },
        {
            "name": "Total polling agents",
            "value": 195
        }
    ],
    "KA": [
        {
            "name": "Total samples collected",
            "value": 700132
        },
        {
            "name": "Total constituencies covered",
            "value": 222
        },
        {
            "name": "Total duration of surveys",
            "value": "288 days"
        },
        {
            "name": "No. of villages covered",
            "value": 4214
        },
        {
            "name": "Total polling agents",
            "value": 992
        }
    ],
    "KL": [
        {
            "name": "Total samples collected",
            "value": 16845
        },
        {
            "name": "Total constituencies covered",
            "value": 32
        },
        {
            "name": "Total duration of surveys",
            "value": "99 days"
        },
        {
            "name": "No. of villages covered",
            "value": 59
        },
        {
            "name": "Total polling agents",
            "value": 64
        }
    ],
    "MH": [
        {
            "name": "Total samples collected",
            "value": 962946
        },
        {
            "name": "Total constituencies covered",
            "value": 287
        },
        {
            "name": "Total duration of surveys",
            "value": "373 days"
        },
        {
            "name": "No. of villages covered",
            "value": 4264
        },
        {
            "name": "Total polling agents",
            "value": 1837
        }
    ],
    "OR": [
        {
            "name": "Total samples collected",
            "value": 663569
        },
        {
            "name": "Total constituencies covered",
            "value": 147
        },
        {
            "name": "Total duration of surveys",
            "value": "194 days"
        },
        {
            "name": "No. of villages covered",
            "value": 3259
        },
        {
            "name": "Total polling agents",
            "value": 1140
        }
    ],
    "PB": [
        {
            "name": "Total samples collected",
            "value": 60527
        },
        {
            "name": "Total constituencies covered",
            "value": 54
        },
        {
            "name": "Total duration of surveys",
            "value": "90 days"
        },
        {
            "name": "No. of villages covered",
            "value": 441
        },
        {
            "name": "Total polling agents",
            "value": 214
        }
    ],
    "TN": [
        {
            "name": "Total samples collected",
            "value": 95512
        },
        {
            "name": "Total constituencies covered",
            "value": 75
        },
        {
            "name": "Total duration of surveys",
            "value": "140 days"
        },
        {
            "name": "No. of villages covered",
            "value": 707
        },
        {
            "name": "Total polling agents",
            "value": 200
        }
    ],
    "UT": [
        {
            "name": "Total samples collected",
            "value": 18688
        },
        {
            "name": "Total constituencies covered",
            "value": 28
        },
        {
            "name": "Total duration of surveys",
            "value": "57 days"
        },
        {
            "name": "No. of villages covered",
            "value": 295
        },
        {
            "name": "Total polling agents",
            "value": 80
        }
    ],
}
