import React from "react";
import { Box, Button, Card, CardBody, Flex, Heading, Input, Text } from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { buttonStyleHover } from "../Styling/components";
import PageHeadings from "../Components/PageHeadings";
import Divider from "../Components/Divider"

class Contact extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "",
            email: "",
            message: ""
        }
    }
    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onSubmit = () => {

        }

        return (
            <Box>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Flex justifyContent="center" paddingTop="8rem" paddingBottom={{ base: "2rem", lg: "6rem" }}>
                        <PageHeadings heading="CONTACT US" />
                    </Flex>
                    <Flex marginBottom="6rem" flexDirection={{ base: "column", xl: "row" }} gap="3" justifyContent="space-between">
                        <Box>
                            <Heading fontSize={{ base: "1.5rem", lg: "1.825rem", xl: "2.5rem" }} fontWeight="400" color="brand.100" fontFamily="body">Get In Touch!</Heading>
                            <Text fontSize="1rem" color="brand.800" marginBottom="1rem">
                                If you have any queries related to the work that we do,
                                please feel free to reach out to us.
                                We will get in touch with you as soon as possible.
                            </Text>
                            <Box>
                                <Flex gap="3" marginBottom="1rem">
                                    <Flex color="brand.600" bgColor="brand.300" width="50px" height="50px" paddingX="1.25rem" borderRadius="50%" justifyContent="center" alignItems="center">
                                        <i style={{ fontSize: "28px" }} class="fa fa-map-marker"></i>
                                    </Flex>
                                    <Box>
                                        <Heading color="brand.100" fontSize={{ base: "1.125rem", lg: "1.25rem" }}>Address</Heading>
                                        <Text fontSize="1rem" color="brand.800">Room No 28, Ravi Towers , 47th Bunder Street,
                                            Tamil Nadu Chennai - 600001</Text>
                                    </Box>
                                </Flex>
                                {/* <Flex gap="3" marginBottom="1rem">
                                    <Flex color="brand.600" bgColor="brand.300" width="50px" height="50px" borderRadius="50%" justifyContent="center" alignItems="center">
                                        <PhoneIcon fontSize="24px" />
                                    </Flex>
                                    <Box>
                                        <Heading color="brand.100" fontSize={{base: "1rem", lg: "1.25rem"}}>Phone Number</Heading>
                                        <Text fontSize={{base: "0.75rem", lg: "1rem"}} color="brand.800">+91 123 123 1234</Text>
                                    </Box>
                                </Flex> */}
                                <Flex gap="3" marginBottom="1rem">
                                    <Flex color="brand.600" bgColor="brand.300" width="50px" height="50px" borderRadius="50%" justifyContent="center" alignItems="center">
                                        <EmailIcon fontSize="24px" />
                                    </Flex>
                                    <Box>
                                        <Heading color="brand.100" fontSize={{ base: "1.125rem", lg: "1.25rem" }}>Email</Heading>
                                        <Text fontSize="1rem" color="brand.800">enquiry@kamakhya.org.in</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Card border="2px" borderColor="brand.200" boxShadow="2xl" maxW={{ base: "100%", xl: "500px" }}>
                            <CardBody textAlign="center" px={["2rem", "2rem", "4rem"]}>
                                <Heading fontSize={{ base: "1.5rem", lg: "1.825rem", xl: "2.5rem" }} color="brand.100" fontFamily="body" marginBottom="2rem">Send A Message</Heading>
                                <form target="_blank" action="https://formsubmit.co/b1726cdd2e38de57cefc79f96014b812" method="POST">
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} onChange={onChange} value={this.state.name} name="name" variant="flushed" placeholder="Name" marginBottom="4" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} onChange={onChange} value={this.state.email} name="email" variant="flushed" placeholder="Email Address" marginBottom="4" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} onChange={onChange} value={this.state.message} name="message" variant="flushed" placeholder="Message" marginBottom="6" />
                                    <Button type="submit" fontFamily="heading" fontSize={{ base: "0.75rem", lg: "1rem" }} fontWeight={{ base: "normal", lg: "bold" }} onClick={onSubmit} _hover={buttonStyleHover} bgGradient="linear(to-t, brand.200, brand.300)" color="brand.600" padding="0px 3rem">
                                        Submit
                                    </Button>
                                </form>

                            </CardBody>
                        </Card>
                    </Flex>
                    <Box marginBottom="2rem" height="400px">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3535694052302!2d80.25210767484097!3d12.94921268736411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d53205d6807%3A0x7d883fdecc43c8ce!2sRavi%20Towers!5e0!3m2!1sen!2sin!4v1725262019641!5m2!1sen!2sin"
                            width="100%" height="100%" style={{ border: "0px", borderRadius: "1rem", margin: "auto" }} title="Office Location"
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </Box>
                </Box>
                <Divider />
            </Box>
        )
    }
}

export default Contact