import React from "react";
import { Flex, Link, Text, Box, Img } from "@chakra-ui/react";
import Logo from "../Assets/Logo.svg"
import withRouter from "./withRouter";
import Menu from "../Assets/Menu.png"

class Toolbar extends React.Component {
    constructor() {
        super()
        this.state = {
            isMenuOpen: false
        }
    }
    render() {

        const menuToggle = () => {
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
        }

        return (
            <Box>
                <Box position="fixed" width="100%" boxShadow="md" zIndex="4">
                    <Flex justifyContent="space-between" alignItems="center" bg="brand.600" p={4}>
                        <Link href="/">
                            <Img src={Logo} alt="kamakhya" width={{base: "50%", lg: "90%"}} margin={{base: "", lg:"auto"}} />
                        </Link>
                        <Box display={{ base: "none", lg: "block" }}>
                            <Flex flexWrap="wrap" align="center" gap="4">
                                <Link bgColor={this.props.location.pathname === "/about-us" && "brand.100"} color={this.props.location.pathname === "/about-us" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/about-us"><Text fontFamily="heading">About Us</Text></Link>
                                {/* <Link bgColor={this.props.location.pathname === "/blogs" && "brand.100"} color={this.props.location.pathname === "/blogs" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/blogs"><Text fontFamily="heading">Blogs</Text></Link> */}
                                {/* <Link bgColor={this.props.location.pathname === "/results" && "brand.100"} color={this.props.location.pathname === "/results" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/results"><Text fontFamily="heading">Results</Text></Link> */}
                                <Link bgColor={this.props.location.pathname === "/career" && "brand.100"} color={this.props.location.pathname === "/career" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/career"><Text fontFamily="heading">Career</Text></Link>
                                <Link bgColor={this.props.location.pathname === "/contact-us" && "brand.100"} color={this.props.location.pathname === "/contact-us" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/contact-us"><Text fontFamily="heading">Contact Us</Text></Link>
                            </Flex>
                        </Box>
                        <Box display={{ base: "block", lg: "none" }}>
                            <Img onClick={menuToggle} src={Menu} alt="kamakhya" width={{base: "2rem", lg: "3rem"}} cursor="pointer" />
                        </Box>
                    </Flex>
                    {this.state.isMenuOpen &&
                        <Box backgroundColor="brand.600" paddingY="2rem" display={{ base: "block", lg: "none" }}>
                            <Flex flexDirection="column" flexWrap="wrap" align="center" gap="4">
                                <Link bgColor={this.props.location.pathname === "/about-us" && "brand.100"} color={this.props.location.pathname === "/about-us" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/about-us"><Text fontFamily="heading" fontWeight="bold">About Us</Text></Link>
                                {/* <Link bgColor={this.props.location.pathname === "/blogs" && "brand.100"} color={this.props.location.pathname === "/blogs" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/blogs"><Text fontFamily="heading" fontWeight="bold">Blogs</Text></Link>*/}
                                {/* <Link bgColor={this.props.location.pathname === "/results" && "brand.100"} color={this.props.location.pathname === "/results" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/results"><Text fontFamily="heading" fontWeight="bold">Results</Text></Link>*/}
                                <Link bgColor={this.props.location.pathname === "/career" && "brand.100"} color={this.props.location.pathname === "/career" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/career"><Text fontFamily="heading" fontWeight="bold">Career</Text></Link>
                                <Link bgColor={this.props.location.pathname === "/contact-us" && "brand.100"} color={this.props.location.pathname === "/contact-us" && "brand.600"} padding="0.25rem 0.75rem" rounded="4" _hover={{ textDecoration: "none", color: "brand.400" }} href="/contact-us"><Text fontFamily="heading" fontWeight="bold">Contact Us</Text></Link>
                            </Flex>
                        </Box>}
                </Box>

            </Box>
        )
    }
}

export default withRouter(Toolbar)
