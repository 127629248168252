import { Box, Flex, Heading, Text, Input, Button, CardBody, Card, Select, Table, Tr, Th, Thead, Tbody, Td, Img, TableContainer } from "@chakra-ui/react";
import React from "react";
import Divider from "../Components/Divider";
import India from "@react-map/india"
import PageHeadings from "../Components/PageHeadings";
import AnimatedGraphics from "../Components/AnimatedGraphics";
import Results from "./Results";
import { mapData } from "../Data/MapData";
import { stateNames } from "../Utils/stateNames";
import BJP from "../Assets/BJP.png"
import INC from "../Assets/INC.png"
import Noservice from "../Assets/no-service.jpg"
import Nothing from "../Assets/Nothing to Show.jpg"

const customStyle = {
    MH: { fill: 'red' }, // Maharashtra in red
    UP: { fill: 'blue' }, // Uttar Pradesh in blue
    TN: { fill: 'green' }, // Tamil Nadu in green
    // Add more states with their respective colors
  };

class HomePage extends React.Component {
    constructor() {
        super()
        this.state = {
            email: "",
            state: "AP",
            stateName: "Andhra Pradesh",
            isStateValid: true
        }
    }
    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
            if (name === "state") {
                this.setState({ stateName: stateNames[value], isStateValid: true })
            }
        }

        const onSubmit = () => {

        }

        const stateSelect = (state) => {
            const isValid = Object.values(stateNames).includes(state)
            this.setState({ isStateValid: isValid, state: "-", stateName: "-" })
            if (isValid) {
                const stateCode = Object.keys(stateNames).find(key => stateNames[key] === state)
                this.setState({ stateName: state, state: stateCode, isStateValid: isValid })
            }
        }

        return (
            <div>
                <div className="home-background">
                    <Flex height="100%" justifyContent="end" margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]} direction="column">
                        <Text color="brand.600" fontSize={{ base: "0.75rem", lg: "1rem" }} marginBottom="0.5rem">Welcome to</Text>
                        <Heading color="brand.400" fontSize={{ base: "1.75rem", lg: "2.25rem", xl: "3rem" }}>KAMAKHYA ANALYTICS FOUNDATION</Heading>
                        <Text maxW="600px" fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600" marginBottom="8rem">
                            We are a team of professionals from diverse backgrounds
                            combining data backed insights with in-depth ground intelligence to
                            provide our clients with high quality, up-to-date surveys.
                        </Text>
                    </Flex>
                </div>
                {/* <Box marginY="6rem">
                    <AnimatedGraphics />
                </Box> */}
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Box marginTop="6rem" marginBottom="2rem">
                        <PageHeadings heading="HARYANA AE 2024 EXIT POLL" />
                    </Box>
                    <TableContainer>
                        <Table size="sm" overflow="hidden" width={{ base: "100%", lg: "40%" }} variant='simple'>
                            <Thead backgroundColor="brand.400" color="brand.100">
                                <Tr>
                                    <Th border="1px" textAlign="center" fontSize="0.825rem">Party</Th>
                                    <Th border="1px" textAlign="center" fontSize="0.825rem">Predicted <br /> Seats</Th>
                                    <Th border="1px" textAlign="center" fontSize="0.825rem">Predicted <br /> Vote Share</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td border="1px solid #D3D3D3">
                                        <Flex gap="3" justifyContent="center">
                                            <img src={BJP} alt="BJP" width="20rem" /> BJP
                                        </Flex>
                                    </Td>
                                    <Td textAlign="center" border="1px solid #D3D3D3">
                                        37 - 46
                                    </Td>
                                    <Td textAlign="center" border="1px solid #D3D3D3">
                                        39% - 41%
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td border="1px solid #D3D3D3">
                                        <Flex gap="3" justifyContent="center">
                                            <img src={INC} alt="INC" width="20rem" /> INC
                                        </Flex>
                                    </Td>
                                    <Td textAlign="center" border="1px solid #D3D3D3">
                                        39 - 48
                                    </Td>
                                    <Td textAlign="center" border="1px solid #D3D3D3">
                                        40% - 43%
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td border="1px solid #D3D3D3">
                                        <Flex gap="3" justifyContent="center">
                                            OTHERS
                                        </Flex>
                                    </Td>
                                    <Td textAlign="center" border="1px solid #D3D3D3">
                                        3 - 5
                                    </Td>
                                    <Td textAlign="center" border="1px solid #D3D3D3">
                                        8% - 14%
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>

                </Box>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Box marginTop="6rem" marginBottom="2rem">
                        <PageHeadings heading="OUR AREAS OF SERVICE" />
                    </Box>
                    <Flex flexDirection={{ base: "column", xl: "row" }} marginBottom="6rem" gap="3" alignItems="center">
                        <Box width={{ base: "100%", xl: "35%" }}>
                            <Select width={{ base: "100%", lg: "50%" }} mb="3rem" onChange={onChange} value={this.state.state} name="state">
                                <option value="AP">Andhra Pradesh</option>
                                <option value="CG">Chhattisgarh</option>
                                <option value="GA">Goa</option>
                                <option value="HP">Himachal Pradesh</option>
                                <option value="HR">Haryana</option>
                                <option value="JH">Jharkhand</option>
                                <option value="JK">Jammu & Kashmir</option>
                                <option value="KA">Karnataka</option>
                                <option value="KL">Kerala</option>
                                <option value="MH">Maharashtra</option>
                                <option value="OR">Odisha</option>
                                <option value="PB">Punjab</option>
                                <option value="TN">Tamil Nadu</option>
                                <option value="UT">Uttarakhand</option>
                                <option style={{display:"none"}} value="-"></option>
                            </Select>
                            <Box display={{base: "block", lg: "block"}}>
                                <India stateStyles={customStyle} onSelect={stateSelect} size="100%" mapColor="#D3D3D3" hoverColor="#3981AF" selectColor="#061746" type='select-single' />
                            </Box>
                        </Box>
                        <Box flexGrow="1">
                            {this.state.state === "" &&
                                <Box>
                                    <Card width={{ base: "100%", lg: "600px" }}>
                                        <CardBody padding="1.5rem 3rem">
                                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.700" textAlign="center">No state has been selected</Text>
                                        </CardBody>
                                    </Card>
                                </Box>

                            }
                            {this.state.state !== "" && this.state.isStateValid &&
                                <Flex justifyContent="center">
                                    <Card boxShadow="lg" border="1px solid #D3D3D3" width={{ base: "100%", lg: "550px" }}>
                                        <CardBody>
                                            <Heading fontSize={{base: "1.25rem", lg: "1.5rem"}} fontFamily="heading" marginBottom="2rem" >{this.state.stateName}</Heading>
                                            {Object.entries(mapData[this.state.state]).map(eachItem => {
                                                return (
                                                    <Flex gap="12" justifyContent="space-between" marginBottom="0.5rem">
                                                        <Text color="brand.100">{eachItem[1].name.toUpperCase()}</Text>
                                                        <Text style={{textWrap: "nowrap"}} color="brand.100">{eachItem[1].value}</Text>
                                                    </Flex>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </Flex>
                            }
                            {!this.state.isStateValid &&
                                <div>
                                    <Flex alignItems="center" marginBottom="6rem" direction="column">
                                        <Img width="400px" src={Nothing} alt="kamakhya" />
                                        <Text color="brand.700">No stats to show.</Text>
                                    </Flex>
                                </div>
                            }
                        </Box>
                    </Flex>
                </Box>
                <Box>
                    <Results />
                </Box>
                <div className="newsletter-background">
                    <Flex justifyContent="space-between" width="100%" flexDirection={{ base: "column", lg: "row" }} margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                        <Box marginTop="2rem">
                            <Heading fontSize={{ base: "1.5rem", lg: "1.825rem", xl: "2.5rem" }} color="brand.400" >NEWSLETTER</Heading>
                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600">
                                Subscribe to our newsletter for the latest updates and exclusive content!
                            </Text>
                        </Box>
                        <Box textAlign="end" marginY="2rem">
                            <Input fontSize={{ base: "0.75rem", lg: "1rem" }} value={this.state.email} name="email" onChange={onChange} bg="brand.600" placeholder='Email Id' marginBottom="2" />
                            <Button fontSize={{ base: "0.75rem", lg: "1rem" }} onClick={onSubmit} _hover={{ color: "brand.100" }} bgGradient="linear(to-t, brand.200, brand.300)" color="brand.600" padding="0px 1rem" fontWeight={{ base: "normal", lg: "bold" }}>
                                Subscribe Here
                            </Button>
                        </Box>
                    </Flex>
                </div>
                <Divider />
            </div>
        )
    }
}

export default HomePage
