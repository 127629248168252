import React from "react";
import withRouter from "../Components/withRouter";
import { client } from "../Utils/contentful";
import { CardBody, Card, Box, Flex, Heading, Badge, Input, InputGroup, InputRightAddon, Select, Button } from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { jobOptions } from "../Styling/openingStyling"
import { buttonStyleHover } from "../Styling/components"
import Divider from "../Components/Divider";
import axios from "axios";
import { baseUrl } from ".."

class CareerApply extends React.Component {
    constructor() {
        super()
        this.state = {
            openingDetails: {},
            name: "",
            email: "",
            phoneNumber: "",
            noticePeriod: "",
            experience: "",
            ctc: "",
            location: "",
            resume: {}
        }
    }

    componentDidMount() {
        const { id } = this.props.params
        client.getEntries({ "fields.id": id, "content_type": "jobPostings" }).then(response => {
            this.setState({ openingDetails: response.items[0] })
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onSubmit = async (e) => {
            const { name, email, phoneNumber, noticePeriod, experience, ctc, location, resume, openingDetails } = this.state
            const data = {
                name, email, phoneNumber, noticePeriod, experience, ctc, location
            }
            const formData = new FormData();
            if (resume.name.split(".")[1] !== "pdf") {
                this.setState({ message: "Please upload pdf only" })
                return
            }
            formData.append("file", resume, `${name}-${openingDetails.title}.${resume.name.split(".")[1]}`)
            
            axios.post(`${baseUrl}/upload-data`, JSON.stringify(data), {
                "headers": {
                    "Content-Type": "application/json"
                }
            }).then (response => response.json()).then (data => {
                console.log (data)
                axios.post (`${baseUrl}/upload-file`, formData).then (response => response.json())
                .then (data => console.log (data)).catch (err => console.log (err.message))
            }).catch (err => console.log (err.message))
        }

        const onFileChange = (e) => {
            this.setState({ resume: e.target.files[0] })
        }

        return (
            <Box>
                <Box paddingTop="8rem" paddingBottom="4rem" margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent="space-between" gap="3" marginBottom="4rem">
                        <Box width={{ base: "100%", xl: "50%" }}>
                            <Heading fontSize={{ base: "1.5rem", lg: "1.825rem", xl: "2.5rem" }} marginBottom="1rem" fontFamily="heading">{this.state.openingDetails.fields?.title}</Heading>
                            <Flex gap="3" flexWrap="wrap" marginBottom="2rem">
                                <Badge fontSize={{ base: "0.75rem", lg: "1rem" }} padding="0.25rem 1rem" rounded="4">
                                    {this.state.openingDetails.fields?.employmentType}
                                </Badge>
                                <Badge fontSize={{ base: "0.75rem", lg: "1rem" }} padding="0.25rem 1rem" rounded="4">
                                    {this.state.openingDetails.fields?.workMode}
                                </Badge>
                                <Badge fontSize={{ base: "0.75rem", lg: "1rem" }} padding="0.25rem 1rem" rounded="4" colorScheme="blue">
                                    Exp : {this.state.openingDetails.fields?.experience} {this.state.openingDetails.fields?.experience === 1 ? "Year" : "Years"}
                                </Badge>
                                <Badge fontSize={{ base: "0.75rem", lg: "1rem" }} padding="0.25rem 1rem" rounded="4" colorScheme="yellow">
                                    {this.state.openingDetails.fields?.department}
                                </Badge>
                            </Flex>
                            <Box>
                                {documentToReactComponents(this.state.openingDetails.fields?.jobDescription, jobOptions)}
                            </Box>
                        </Box>
                        <Box width={{ base: "100%", xl: "35%" }}>
                            <Card variant="elevated" shadow="xl" border="1px" borderColor="brand.100" maxH="max-content">
                                <CardBody>
                                    <Heading fontFamily="heading" marginBottom="1rem">Application</Heading>
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='Name' onChange={onChange} value={this.state.name} name="name" marginBottom="1rem" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='Email Address' onChange={onChange} value={this.state.email} name="email" marginBottom="1rem" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='Phone Number' onChange={onChange} value={this.state.phoneNumber} name="phoneNumber" marginBottom="1rem" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='Notice Period Duration (in days)' onChange={onChange} value={this.state.noticePeriod} name="noticePeriod" marginBottom="1rem" />
                                    <Select fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='Years of Experience' onChange={onChange} name="experience" value={this.state.experience} marginBottom="1rem">
                                        <option value='0 - 2 Years'>0 - 2 Years</option>
                                        <option value='2 - 4 Years'>2 - 4 Years</option>
                                        <option value='4 - 7 Years'>4 - 7 Years</option>
                                        <option value='7 - 10 Years'>7 - 10 Years</option>
                                        <option value='10+ Years'>10+ Years</option>
                                    </Select>
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='CTC (in lpa)' onChange={onChange} value={this.state.ctc} name="ctc" marginBottom="1rem" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} placeholder='Current Location' onChange={onChange} value={this.state.location} name="location" marginBottom="1rem" />
                                    <Input onChange={onFileChange} name="cv" placeHolder="Upload Resume" type="file" marginBottom="1rem" />
                                    <Button fontSize={{ base: "0.75rem", lg: "1rem" }} fontWeight={{ base: "normal", lg: "bold" }} onClick={onSubmit} _hover={buttonStyleHover} bgGradient="linear(to-t, brand.200, brand.300)" color="brand.600" padding="0px 3rem">
                                        Submit
                                    </Button>
                                </CardBody>
                            </Card>
                        </Box>
                    </Flex>
                </Box>
                <Divider />
            </Box>

        )
    }
}

export default withRouter(CareerApply)