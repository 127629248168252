import { Box, Heading, Text } from "@chakra-ui/react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

export const blogOptions = {
    renderNode: {
        [BLOCKS.HEADING_1]: (node, children) =>
            <div>
                <Heading as="h1" size="3xl" fontFamily="heading">
                    {children}
                </Heading>
            </div>,
        [BLOCKS.HEADING_2]: (node, children) =>
            <div>
                <Heading as="h2" size="2xl" fontFamily="heading">
                    {children}
                </Heading>
            </div>,
        [BLOCKS.HEADING_3]: (node, children) =>
            <div>
                <Heading as="h3" size="xl" fontFamily="heading">
                    {children}
                </Heading>
            </div>,
        [BLOCKS.HEADING_4]: (node, children) =>
            <div>
                <Heading as="h4" size="lg" fontFamily="body">
                    {children}
                </Heading>
            </div>,
        [BLOCKS.HEADING_5]: (node, children) =>
            <div>
                <Heading as="h5" size="md" fontFamily="body">
                    {children}
                </Heading>
            </div>,
        [BLOCKS.HEADING_6]: (node, children) =>
            <div>
                <Heading as="h6" size="sm" fontFamily="body">
                    {children}
                </Heading>
            </div>,
        [MARKS.BOLD]: (node, children) =>
            <div>
                <Text as="b" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.ITALIC]: (node, children) =>
            <div>
                <Text as="i" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.UNDERLINE]: (node, children) =>
            <div>
                <Text as="u" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.SUBSCRIPT]: (node, children) =>
            <div>
                <Text as="sub" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.SUPERSCRIPT]: (node, children) =>
            <div>
                <Text as="sup" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [MARKS.STRIKETHROUGH]: (node, children) =>
            <div>
                <Text as="s" fontFamily="body">
                    {children}
                </Text>
            </div>,
        [BLOCKS.UL_LIST]: (node, children) =>
            <div>
                <ul style={{ padding: "0px 1rem" }}>
                    {children}
                </ul>
            </div>,
        [BLOCKS.OL_LIST]: (node, children) =>
            <div>
                <ol style={{ padding: "0px 1rem" }}>
                    {children}
                </ol>
            </div>,
        [BLOCKS.QUOTE]: (node, children) =>
            <div>
                <q>
                    {children}
                </q>
            </div>,
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <Box marginBottom="1rem">
                <Text fontSize={{ base: "0.75rem", lg: "1rem" }} fontFamily="body" textAlign="justify">
                    {children}
                </Text>
            </Box>,
        [BLOCKS.EMBEDDED_ASSET]: (node, children) =>
            <Box marginY="1rem">
                <img
                    src={`https://${node.data.target.fields.file.url}`}
                    height={node.data.target.fields.file.details.image.height}
                    width={node.data.target.fields.file.details.image.width}
                    alt={node.data.target.fields.description}
                />
            </Box>,

    }
}