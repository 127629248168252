import React from "react";
import withRouter from "../Components/withRouter";
import { client } from "../Utils/contentful"
import { Img, Text, Box, Heading } from "@chakra-ui/react";
import Moment from "react-moment";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { blogOptions } from "../Styling/blogStyling"
import Divider from "../Components/Divider";

class BlogContent extends React.Component {
    constructor() {
        super()
        this.state = {
            blogDetails: {}
        }
    }

    componentDidMount() {
        const { id } = this.props.params
        client.getEntries({ "fields.id": id, "content_type": "blogs" }).then(response => {
            this.setState({ blogDetails: response.items[0] }, () => console.log(this.state.blogDetails.fields?.fullBlog))
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {
        return (
            <Box>
                <Box w={{ lg: "60%" }} paddingTop="8rem" paddingBottom="4rem" margin={{ base: "0px 1rem", lg: "0px 2rem", xl: "auto" }}>
                    <Img alignItems="center" rounded="8" src={this.state.blogDetails.fields?.thumbnail.fields.file.url} alt="kamakhya" marginBottom="1rem" w="100%" />
                    <Text fontSize={{base: "0.75rem", lg: "1rem"}} color="brand.700" fontFamily="body" textAlign="start" marginBottom="2rem">
                        <Moment format="DD MMMM YYYY">
                            {this.state.blogDetails.fields?.date}
                        </Moment> | {this.state.blogDetails.fields?.readingTime} {this.state.blogDetails.fields?.readingTime === 1 ? "min" : "mins"}
                    </Text>
                    <Heading fontSize={{base: "1.5rem", lg: "1.825rem", xl: "2.5rem"}} fontFamily="heading" marginBottom="1rem">
                        {this.state.blogDetails.fields?.heading}
                    </Heading>
                    <Box>
                        {documentToReactComponents(this.state.blogDetails.fields?.fullBlog, blogOptions)}
                    </Box>
                </Box>
                <Divider />
            </Box>

        )
    }
}

export default withRouter(BlogContent)