export const stateNames = {
    "AP": "Andhra Pradesh",
    "CG": "Chhattisgarh",
    "GA": "Goa",
    "HP": "Himachal Pradesh",
    "HR": "Haryana",
    "JH": "Jharkhand",
    "JK": "Jammu & Kashmir",
    "KA": "Karnataka",
    "KL": "Kerala",
    "MH": "Maharashtra",
    "OR": "Odisha",
    "PB": "Punjab",
    "TN": "Tamil Nadu",
    "UT": "Uttarakhand"
}
