import { extendTheme } from "@chakra-ui/react";
import "@fontsource/montserrat"
import "@fontsource/source-sans-pro"

export const theme = extendTheme ({
    colors: {
        brand: {
            100: "#061746",
            200: "#19416F",
            300: "#3981AF",
            400: "#9AC0D1",
            500: "#CDDCE3",
            600: "#FFFFFF",
            700: "#A0A0A0",
            800: "#827E7E",
            900: "#FAFAFA"
        }
    },
    fonts: {
        heading: `'Montserrat', sans-serif`,
        body: `'Source Sans Pro', sans-serif`
    },
    spacing: {
        1: "0.5rem",
        2: "1rem",
        3: "2rem",
        4: "4rem"
    },
    breakpoints: {
        base: "0px",
        sm: "320px",
        md: "400px",
        lg: "769px",
        xl: "1024px",
        '2xl': "1200px"
    },
    heading: {
        sm: "1.2rem",
        md: "1.5rem",
        lg: "1.75rem",
        xl: "2rem"
    }
})