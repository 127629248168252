export const haryanaTrendData = [
    {
        name: 'July',
        "BJP": 15,
        "INC": 30
    },
    {
        name: 'Aug',
        "BJP": 10,
        "INC": 12
    },
    {
        name: 'Sep',
        "BJP": 25,
        "INC": 7
    },
    {
        name: 'Oct',
        "BJP": 18,
        "INC": 9
    },
    {
        name: 'Nov',
        "BJP": 20,
        "INC": 2
    }
];

export const haryanaTableData = {
    "heading": ["Party", "Our Prediction", "Actual Seats", "Axis My India", "C Voter", "D Matrix"],
    "data": [
        ["BJP", "20-25", "22", "17-19", "10-14", "17-28"],
        ["INC", "20-25", "22", "17-19", "10-14", "17-28"],
        ["AAP", "20-25", "22", "17-19", "10-14", "17-28"]
    ]
}

export const chattisgarhRangeTableData = {
    "heading": ["Party", "Our Prediction", "Actual Results", "Axis My India", "CVoter", "Today's Chanakya", "Matrize", "Jan Ki Baat"],
    "data": [
        ["BJP", "43-47", "54", "36-46", "36-48", "25-41", "34-42", "34-45"],
        ["INC", "40-45", "35", "40-50", "41-53", "49-65", "44-52", "42-53"],
        ["OTHERS", "0-1", "1", "1-5", "0-3", "0-3", "0-2", "3"]
    ]
}

export const hpRangeTableData = {
    "heading": ["Party", "Our Prediction", "Actual Results", "Axis My India", "CVoter", "Today's Chanakya", "Matrize", "Jan Ki Baat"],
    "data": [
        ["BJP", "33-39", "25", "24-34", "33-41", "33", "35-40", "32-40"],
        ["INC", "27-32", "40", "30-40", "24-32", "33", "26-31", "27-34"],
        ["OTHERS", "0", "3", "4-8", "0-4", "2", "0-3", "1-2"]
    ]
}

export const kt23RangeTableData = {
    "heading": ["Party", "Our Prediction", "Actual Results", "Axis My India", "CVoter", "Today's Chanakya", "Matrize", "Jan Ki Baat"],
    "data": [
        ["BJP", "54-57", "66", "71", "83-95", "92", "86", "106"],
        ["INC", "108-112", "135", "131", "100-112", "120", "108", "98"],
        ["JD(S)", "14-18", "19", "22", "21-29", "12", "28", "19"],
        ["OTHERS", "2-3", "4", "-", "4", "0", "2", "1"]
    ]
}

export const kt24RangeTableData = {
    "heading": ["Alliance", "Our Prediction", "Actual Results", "Axis My India", "CVoter", "Today's Chanakya", "Matrize", "Jan Ki Baat"],
    "data": [
        ["NDA", "19-22", "19", "23-25", "23-25", "20-28", "21", "19-25"],
        ["INDIA", "5-8", "9", "3-5", "3-5", "0-8", "7", "4-8"]
    ]
}

export const mhRangeTableData = {
    "heading": ["Alliance", "Our Prediction", "Actual Results", "CVoter", "Today's Chanakya", "Matrize"],
    "data": [
        ["NDA", "30-38", "18", "22-26", "33", "30-36"],
        ["INDIA", "10-15", "30", "23-25", "15", "13-19"],
        ["OTHERS", "0", "1", "-", "-", "-"]
    ]
}

export const odAeRangeTableData = {
    "heading": ["Alliance", "Our Prediction", "Actual Results", "Axis My India"],
    "data": [
        ["BJP", "76-80", "78", "62-80"],
        ["INC", "0", "14", "5-8"],
        ["BJD", "60-65", "51", "62-80"],
        ["OTHERS", "2-4", "4", "-"]
    ]
}

export const odPeRangeTableData = {
    "heading": ["Alliance", "Our Prediction", "Actual Results", "Axis My India", "CVoter", "Today's Chanakya", "Matrize", "Jan Ki Baat"],
    "data": [
        ["NDA", "17-19", "20", "18-20", "17-19", "16", "9-12", "15-18"],
        ["INDIA", "1", "1", "0-1", "0-2", "1", "0-1", "0"],
        ["BJD", "-", "0", "0-2", "1-3", "4", "7-10", "3-7"],
        ["OTHERS", "1", "1", "-", "-", "-", "-", "-"]
    ]
}

export const allStateData = {
    "HR": {
        "AE": {
            "2024": {
                "summary": {
                    "Total Data Points": "10 K+",
                    "People Surveyed": "575",
                    "Number of Rounds": "5",
                    "Time taken for survey": "375 hrs"
                },
                "graphData": {
                    "trendGraphData": haryanaTrendData,
                    "rangeGraphData": haryanaTrendData,
                },
                "tableData": {
                    "rangeTableData": haryanaTableData,
                    "roundTableData": haryanaTableData
                }

            }
        },
        "PE": {
            "2022": {
                "summary": {
                    "Total Data Points": "12K+",
                    "People Surveyed": "612",
                    "Number of Rounds": "4",
                    "Time taken for survey": "370 hours"
                },
                "graphData": {
                    "trendGraphData": haryanaTrendData,
                    "rangeGraphData": haryanaTrendData,
                },
                "tableData": {
                    "rangeTableData": haryanaTableData,
                    "roundTableData": haryanaTableData
                }
            }
        }
    },
    "CH": {
        "AE": {
            "2023": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": chattisgarhRangeTableData,
                    "roundTableData": chattisgarhRangeTableData
                }
            }
        }
    },
    "HP": {
        "AE": {
            "2022": {
                "summary": {},
                "tableData": {
                    "rangeTableData": hpRangeTableData,
                    "roundTableData": hpRangeTableData
                }
            }
        }
    },
    "KT": {
        "AE": {
            "2023": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": kt23RangeTableData,
                    "roundTableData": kt23RangeTableData
                }
            }
        },
        "PE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": kt24RangeTableData,
                    "roundTableData": kt24RangeTableData
                }
            }
        }
    },
    "MH": {
        "PE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": mhRangeTableData,
                    "roundTableData": mhRangeTableData
                }
            }
        }
    },
    "OD": {
        "AE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": odAeRangeTableData,
                    "roundTableData": odAeRangeTableData
                }
            }
        },
        "PE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": odPeRangeTableData,
                    "roundTableData": odPeRangeTableData
                }
            }
        }
    }
}