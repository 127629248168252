import React from "react"
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{}}
            onClick={onClick}>
                <i className="bi bi-chevron-right"></i>
        </div>
    );
}

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{  }}
            onClick={onClick}>
                <i className="bi bi-arrow-right"></i>
        </div>
    );
}

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    className: "center",
    centerPadding: "1px",
    arrows: true,
    indicator: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }
    ]
};

class TeamCarousel extends React.Component {
    render() {
        return (
            <div className="sm:mx-3 slider-container">
                <Slider {...settings}>
                    {this.props.children}
                </Slider>
            </div>
        )
    }
}

export default TeamCarousel